<template>
	<div class="add-friend-import">
		<el-dialog class = "importcalss" title="" :visible.sync="visible" width="850px" :show-close="false" :close-on-click-modal="false">

			<el-tabs v-model="activeName">
				<el-tab-pane label="Excel导入" name="excel"></el-tab-pane>
				<el-tab-pane label="订单导入" name="order"></el-tab-pane>
				<el-tab-pane label="客户标签导入" name="tag"></el-tab-pane>
			</el-tabs>

			<div class="content">
				<div class="import-tips" v-if="activeName === 'excel'">
					<div>操作提示：</div>
					<div>1.请在模板中输入手机号上传，<el-link type="primary" href="http://cdn.dkycn.cn/202107081710.xlsx" target="_blank">点击下载模板
						</el-link>
					</div>
					<div>2.若导入的手机号重复，系统将会自动过滤</div>
					<div>3.单次导入最多支持3000行数据，如超过请分多次操作</div>
					<div>4.若出现导入失败的手机号，可下载失败数据查看具体原因</div>
				</div>

				<div v-if="activeName === 'excel'">
					<div class="upload">
						上传表格：
						<el-upload :action="uploadUrl" 
							:show-file-list="false"
							:on-success="handleUploadSuccess"
							:before-upload="beforeUpload">
							<el-button size="mini" v-if="fileName">重新选择</el-button>
							<el-button size="mini" v-else>选择文件</el-button>
						</el-upload>
						<span class="file-name" v-if="fileName">{{fileName}}</span>
						<span class="file-name" v-else>未选择任何文件</span>
					</div>
					<div style="margin-top:15px">
						<span>客户来源：</span>
						<el-select v-model="CustomerSource" allow-create filterable default-first-option clearable
						 placeholder="输入来源渠道名称查询或新建" style="width:300px">
							<div slot="empty"  class = "grayfont" style="padding:7px 0px;text-align:center;max-width:100%">
								暂无数据，输入来源渠道名称即可新增
							</div>
							<el-option v-for="(v,i) in sourcelist" :key="i" :value="v" :label="v"></el-option>
						</el-select>
						<div class = "grayfont" style="margin:5px 0px 0px 70px">选择客户来源，后续可该来源查看加好友转化数据以及客户交易转化数据等</div>
					</div>
				</div>
				
				<div class="submit-order-time" v-if="activeName === 'order'">
					<span class="form-title">支付业绩归属门店：</span>
					<el-select filterable v-model="ShopId" style="width: 350px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in dropList" :key="item.Id" :label="item.ShopName" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<div class="submit-order-time" v-if="activeName === 'order'" style="margin-top: 20px;">
					<span class="form-title">下单时间：</span>
					<el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" :clearable="false"
						end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="submit-order-time" style="margin-top: 20px;" v-if="activeName === 'order'">
					<span class="form-title">优先锁粉店员：</span>
					<el-switch v-model="fansFirst"></el-switch>
					<div style="font-size: 12px;color: #999;margin-left: 148px;margin-top: 10px;">
						若下单用户有锁粉店员，开启后，默认把收货人手机号分配给锁粉店员；没有锁粉店员或锁粉店员不在职，将分配给选择的员工
					</div>
				</div>

				
				<div v-if="activeName === 'tag'">
					<div>
						<span class="form-title tagtitle">选择标签：</span>
						<el-button type="text" @click="dialogTag=true">选择标签</el-button>
						<div class = "flexRow" style="flex-wrap:wrap;margin-left:140px">
							<el-tag v-for="(v,i) in taglist" :key="i" style="margin:0px 5px 5px 0px" closable @close="closetag(i)">{{v.Name}}</el-tag>
						</div>
					</div>
					<div style="margin-top:15px">
						<span class="form-title">优先锁粉店员：</span>
						<el-switch v-model="isopen"></el-switch>
						<div class = "grayfont" style="margin-left:145px">
							被打上该标签的客户有锁粉店员，开启后，默认将对应客户的手机号分配给锁粉店员；没有锁粉店员或锁粉店员不在职，分配给选择的员工
						</div>
					</div>
				</div>

				<div class="select-employee">
					<span class="form-title tagtitle" v-if="activeName === 'order'|| activeName === 'tag'">分配员工：</span>
					<span v-else>分配员工：</span>
					<el-link type="primary" @click="showSelectPop">选择员工</el-link>
					<div class="employee-list" v-if="selectList&&selectList.length"
					 style="margin-bottom: 10px;" :style="{marginLeft: activeName === 'order' ? '148px':'70px'}">
						<div class = 'flexRow' :style="{'margin-left':activeName === 'tag'?'75px':'0px','flex-wrap':'wrap'}">
							<div class="employee-item" v-for="(item, index) in selectList" :key="index">
								<img class="e-hd" v-if="item.Type===3 && item.HeadUrl" :src="item.HeadUrl" />
								<span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span>
								<i class="el-icon-s-shop e-shop" v-if="item.Type!==3" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
								{{item.Name}}
								<i class="el-icon-close e-delete" @click="handleDeleteEmployee(index)"></i>
							</div>
						</div>
					</div>
					<div class="select-tip" :style="{marginLeft: activeName === 'order' ? '148px':'70px',marginTop:'10px'}">
						<div v-if="activeName === 'tag'" style="margin-left:75px">
							根据已选择的客户标签，将被打上该标签的客户手机号平均分配给选择的员工（无手机号的客户不参与分配）。
							员工会在企业微信收到添加好友的任务，需要员工手动添加。本次导入并不会绑定与员工的锁粉关系
						</div>
						<span v-else>
							根据筛选的下单时间范围，拉取收货人手机号，并将手机号平均分配给选择的员工。员工会在企业微信收到添加好友的任务，
							需要员工手动添加。你也可以在导入成功后，列表上操作批量分配。本次导入并不会绑定与员工的锁粉关系
						</span>
					</div>
				</div>

			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" :loading="exportLoading" @click="handleExport">确 定</el-button>
			</span>
		</el-dialog>

		<div class="normal-dialog">
			<el-dialog title="选择员工" :visible.sync="employeeVisible" width="900px">
				<div class="list-box">
					<div class="list-left">
						<div class="flex0">
							<el-input placeholder="员工姓名、手机号"  @input="treeSearch" v-model="keywords" style="width: 250px;margin-bottom: 10px;margin-right: 10px;"></el-input>
							<!-- <el-button type="primary" @click="treeSearch" size="mini">搜索</el-button> -->
						</div>
						<div class="flex1" v-if="!keywords">
							<el-tree :data="list" node-key="Id" :props="options" :default-expanded-keys="defaultKey" :expand-on-click-node="true">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<span style="color: #333;" v-if="data.Type !== 3">
										<i class="el-icon-s-shop" style="color: #409eff;margin-right: 5px;font-size: 16px;"></i>
										{{ node.label }}
										({{data.EmployeeCount}})
									</span>
									<span style="color: #333;font-size: 13px;" v-else>
										<img v-if="data.HeadUrl" class="hd" :src="data.HeadUrl"></img>
										<span class="hd-normal" v-else>员</span>
										{{ node.label }}
										({{data.Phone}})
									</span>
									<!-- handleStop就是为了阻止冒泡， 没其它用途 -->
									<span @click.stop="handleStop">
										<el-checkbox v-model="data.checked" @change="(e)=>{checkChange(e, data)}"></el-checkbox>
									</span>
								</span>
							</el-tree>
						</div>
						<div class="flex1" v-else>
							<div class="select-list" v-if="searchList.length">
								<div class="select-item select-item1" style="margin-bottom: 10px;" v-for="(item, index) in searchList" :key="item.Id">
									<span style="color: #333;font-size: 13px;">
										<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl"></img>
										<span class="hd-normal" v-else>员</span>
										{{ item.Name }}
										({{item.Phone}})
									</span>
									<span @click.stop="handleStop">
										<el-checkbox v-model="item.checked" @change="(e)=>{checkChange(e, item)}"></el-checkbox>
									</span>
								</div>
							</div>
							<div v-else style="text-align: center;font-size: 13px;color: #999;padding-top: 100px;">搜索无结果，请确认输入的名字或手机号是否正确</div>
						</div>
					</div>
					<div class="list-right">
						<div class="title">已选择的归属门店或员工</div>
						<div class="select-list">
							<div class="select-item" v-for="(item, index) in selectedData" :key="item.Id">
								<span style="color: #333;" v-if="item.Type !== 3">
									<i class="el-icon-s-shop" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
									{{ item.Name }}
								</span>
								<span style="color: #333;font-size: 13px;" v-else>
									<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl"></img>
									<span class="hd-normal" v-else>员</span>
									{{ item.Name }}
									({{item.Phone}})
								</span>
								<i class="el-icon-close delete-btn" @click="handleDeleteSelect(index)"></i>
							</div>
						</div>
					</div>
				</div>
				
				<span slot="footer" class="dialog-footer">
					<el-button @click="employeeVisible=false">取 消</el-button>
					<el-button type="primary" @click="handleSureSelect">确 定</el-button>
				</span>
				
			</el-dialog>
		</div>
		<!-- 选择标签 -->
		<tagging title="选择标签"  :dialogTag="dialogTag" :selected="taglist" @close="dialogTag=false" @confirm="confirmtag" tagkey="TagDetailId"></tagging>
	</div>

</template>

<script>
	import config from '@/config';
	import tagging from "./tagging"
	import {
		chooseEmployee,
		qyWeixinAddFriendimport
	} from '@/api/sv1.0.0.js';
	
	import {
		shopdroplist
	} from '@/api/api'

	export default {
		components: {
			tagging,
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			sourcelist:{
				type: Array,
				default:()=>{
					return []
				}
			}
		},
		created () {
			if(this.$route.params.TagDetailId){
				this.activeName = 'tag'
				this.taglist = []
				let data = JSON.parse(JSON.stringify(this.$route.params))
				data.TagDetailId = Number(data.TagDetailId)
				this.taglist.push(data)
			}
		},
		data() {
			return {
				options: {
					children: 'NextList',
					label: 'Name'
				},
				uploadUrl: config.UPLOAD_ZIP,
				imgUrl: config.IMG_BASE,
				activeName: 'excel',
				date: '',
				selectList: [],
				searchList: [],
				
				exportLoading: false,
				employeeVisible: false,
				list: [],
				defaultKey: [],
				selectedData: [],
				
				keywords: '',
				
				fileName: '',
				fileUrl: '',
				fansFirst: false,
				
				dropList: [],
				ShopId: null,

				dialogTag:false,
				importVisible:false,
				taglist:[],
				isopen:false,

				CustomerSource:null,
			}
		},
		watch: {
			visible() {
				if (this.visible) {
					this.getList();
				}
			}
		},
		beforeMount() {
			this.getList()
			this.getShopdroplist()
		},
		methods: {
			closetag(i){
				this.taglist.splice(i,1)
			},
			confirmtag(list){
				// console.log(list)
				this.taglist = list
				this.dialogTag = false
			},
			async getShopdroplist() {
				try {
					let doorResult = await shopdroplist({
						IsOpenRoleJurisdiction: true
					})
					this.dropList = this.dropList.concat(doorResult.Result)
				} catch (e) {}
			},
			async getList() {
				try {
					const res = await chooseEmployee();
					var list = [res.Result];
					
					list.map(item=>{
						item.checked = false;
						item.NextList.map(item1=>{
							item1.checked = false;
							
							item1.NextList.map(item2=>{
								item2.checked = false;
								return item2;
							})
							
							return item1;
						})
						
						return item;
					})
					
					this.defaultKey = [-1];
					this.list = list;
				} catch (e) {
					//TODO handle the exception
				}
			},
			beforeUpload(file){
				var type = file.name.split('.')[1];
				if (['xls', 'xlsx'].indexOf(type) > -1){
					this.fileName = file.name;
					return true;
				}else{
					return false;
				}
			},
			handleUploadSuccess(res){
				this.fileUrl = res[0];
			},
			handleClose() {
				this.$emit('close')
			},
			checkChange(e, record){
				// console.log(e)
				var obj = JSON.parse(JSON.stringify(record));
				delete obj.NextList;
				if (e){
					this.selectedData.push(obj);
				}else{
					this.selectedData = this.selectedData.filter(item=>{
						return item.Id != obj.Id
					})
				}
				// console.log(this.selectedData)
			},
			handleDeleteSelect(index){
				this.selectedData.splice(index, 1);
				this.treeCheckChange();
			},
			treeCheckChange(){
				var list = JSON.parse(JSON.stringify(this.list));
				var ids = this.selectedData.map(item=>{
					return item.Id
				})
				
				this.list.map(item=>{
					item.checked = ids.indexOf(item.Id) > -1;
					item.NextList.map(item1=>{
						item1.checked = ids.indexOf(item1.Id) > -1;;
						
						item1.NextList.map(item2=>{
							item2.checked = ids.indexOf(item2.Id) > -1;;
							return item2;
						})
						
						return item1;
					})
					
					return item;
				})
			},
			handleStop(){},
			treeSearch(){
				var list = []
				if (this.keywords){
					this.list.map(item=>{
						item.NextList.map(item1=>{
							item1.NextList.map(item2=>{
								if (item2.Name.indexOf(this.keywords) > -1 || item2.Phone.indexOf(this.keywords) > -1){
									list.push(item2);
								}
							})
						})
					})
					this.searchList = list;
				}else{
					this.treeCheckChange();
				}
			},
			
			handleSureSelect(){

				if (!this.selectedData.length){
					this.$message.error('请选择归属门店或员工');
					return;
				}
				
				this.selectList = JSON.parse(JSON.stringify( this.selectedData));
				this.employeeVisible = false;
			},
			handleDeleteEmployee(index){
				this.selectList.splice(index, 1);
				this.selectedData.splice(index, 1);
			},
			showSelectPop(){
				this.employeeVisible = true;
				// this.getList()
				this.treeCheckChange();
			},
			
			
			handleExport(){
				if (this.activeName === 'excel'){
					if (!this.fileUrl){
						this.$message.error('请上传表格');
						return;
					}
				}else if(this.activeName === 'order'){
					if (!this.date){
						this.$message.error('请选择下单时间');
						return;
					}else if (this.moreThen30Day()){
						this.$message.error('最多可选择30天');
						return;
					}
				}else{
					if(!this.taglist||!this.taglist.length){
						this.$message.error('请选择客户标签');
						return
					}else if(this.taglist.length!=1){
						this.$message.error('按标签导入，目前仅支持选中1个标签下的客户');
						return 
					}
				}
				
				if (!this.selectList.length){
					this.$message.error('请选择分配员工');
					return;
				}
				
				this.sureExport();
			},
			async sureExport(){
				let params = {
					ChooseInfoList: this.selectList.map(item=>{
						return {
							Id: item.Id,
							Type: item.Type
						}
					})
				}
				
				if (this.activeName == 'excel'){
					params.ImportChannel = 1
					params.ExcelFilePath = this.fileUrl;
					params.ExcelFileName = this.fileName;
					params.CustomerSource = this.CustomerSource
				}else if (this.activeName == 'order'){
					params.ImportChannel = 2
					params.StartTime = this.date[0];
					params.EndTime  = this.date[1];
					params.EndTime  = this.date[1];
					params.IsFirstLockMallEmployee = this.fansFirst;
					params.MallEmployeeShopId = this.ShopId
				}else if(this.activeName == 'tag'){
					params.ImportChannel = 3
					params.IsFirstLockMallEmployee = this.isopen
					params.TagDetailId  = this.taglist[0].TagDetailId
				}
				
				this.exportLoading = true;
				try{
					// console.log(params)
					const res = await qyWeixinAddFriendimport(params);
					if (res.IsSuccess){
						this.$message.success('操作成功');
						this.handleClose();
						this.$emit('reload')
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.exportLoading = false;
				}
			},
			moreThen30Day(){
				var start = this.formatDate(this.date[0]);
				var end = this.formatDate(this.date[1], 'complete');
				var day = (end.getTime()-start.getTime())/60/60/24/1000;

				return day > 30;
			},
			formatDate(dt, type){
				var year = dt.split('-')[0];
				var month = +dt.split('-')[1]-1;
				var day = +dt.split('-')[2];
				
				if (type){
					return new Date(year, month, day+1);
				}
				return new Date(year, month, day);
			}
		}
	}
</script>

<style lang="less">
	.add-friend-import {

		font-size: 14px;

		.import-tips {
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			padding: 10px;
			line-height: 24px;
			color: #999;
			border-radius: 4px;
		}
		
		.form-title{
			display: inline-block;
			width: 140px;
			text-align: right;
			margin-right: 5px;
		}
		.tagtitle::before{
			content: '*';
			color: #f56c6c;
			margin-right:4px
		}
		
		.hd{
			width: 18px;
			height: 18px;
			border-radius: 2px;
			vertical-align: -3px;
		}
		.hd-normal{
			display: inline-block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			font-size: 12px;
			color: #fff;
			border-radius: 2px;
			vertical-align: 1px;
			background-color: #409eff;
		}
		.custom-tree-node{
			flex: 1;
			display: flex;
			justify-content: space-between;
			padding-right: 10px;
		}
		
		.list-box{
			display: flex;
			height: 60vh;
			
			.list-left{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: hidden;
				border-right: 1px solid #ddd;
				box-sizing: border-box;
				
				display: flex;
				flex-direction: column;
				
				.flex0{
					flex: 0 0 auto;
				}
				.flex1{
					flex: 1 1 auto;
					overflow: auto;
				}
			}
			
			.list-right{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: auto;
				padding-left: 20px;
				box-sizing: border-box;
				
				.title{
					margin-bottom: 15px;
				}
				
				.select-list{
					.select-item{
						position: relative;
						margin-bottom: 15px;
					}
					
					.el-icon-close{
						position: absolute;
						right: 20px;
						top: 0px;
						font-size: 18px;
						color: #999;
						cursor: pointer;
					}
				}
			}
		}
		
		.select-item1{
			display: flex;
			justify-content: space-between;
			margin-right: 20px;
		}

		.upload {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.file-name {
				font-size: 12px;
				padding-left: 10px;
				padding-top: 3px;
			}
		}

		.content {
			max-height: 500px;
			overflow: auto;
		}

		.select-employee {
			margin-top: 20px;

			.select-tip {
				margin-left: 70px;
				font-size: 12px;
				color: #999;
				line-height: 18px;
			}

			.employee-list {
				margin-left: 70px;
				margin-top: 15px;
				display: flex;
				flex-wrap: wrap;
				// max-width: 90%;
				// border:1px solid black;

				.employee-item {
					display: flex;
					align-items: center;
					height: 26px;
					margin-right: 10px;
					margin-bottom: 5px;
					padding: 0 5px;
					font-size: 12px;
					border: 1px solid #ddd;
					border-radius: 3px;

					.e-hd {
						width: 18px;
						height: 18px;
						overflow: hidden;
						border-radius: 2px;
						margin-right: 5px;
						background-color: #F6F6F6;
					}
					.e-no-hd{
						display: inline-block;
						width: 18px;
						height: 18px;
						line-height: 18px;
						text-align: center;
						font-size: 12px;
						color: #fff;
						border-radius: 2px;
						vertical-align: 1px;
						background-color: #409eff;
						margin-right: 5px;
					}

					.e-delete{
						margin-left: 5px;
						font-size: 14px;
						cursor: pointer;
					}
				}
			}
		}

		.importcalss .el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding-top: 20px;
		}
		
		.normal-dialog .el-dialog__header {
			display: block;
		}
	}
</style>
<style lang = "less" scoped>
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.grayfont{
		font-size: 12px;
		line-height: 1.5;
		color: #999999;
		max-width: 80%;
	}
	.e-hd {
		width: 18px;
		height: 18px;
		overflow: hidden;
		border-radius: 2px;
		margin-right: 5px;
		background-color: #F6F6F6;
	}
	.e-no-hd{
		display: inline-block;
		width: 18px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		font-size: 12px;
		color: #fff;
		border-radius: 2px;
		vertical-align: 1px;
		background-color: #409eff;
		margin-right: 5px;
	}
	.formlabel ::v-deep .el-form-item__label{
		font-weight: 100;
	}
</style>
