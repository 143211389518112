<template>
	<batchAddFriendNoAuth>
		<div class="container">
			<el-card v-loading="dataLoading">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="导入客户" name="1"></el-tab-pane>
					<el-tab-pane label="数据统计" name="2"></el-tab-pane>
				</el-tabs>
				<div v-if="activeName==1">
					<div class="search">
						<div style="margin-right: 150px;">
							<span style="font-weight: bold;padding-right: 4px;color: #606266;">关键字:</span>
							<el-input v-model="keywords" placeholder="手机号" style="width: 200px;margin-right: 10px;margin-bottom: 10px;"></el-input>
							<span style="font-weight: bold;padding-right: 4px;color: #606266;">好友状态:</span>
							<el-select v-model="friendStateValue" placeholder="请选择"
								style="width: 140px;margin-right: 20px;margin-bottom: 10px;">
								<el-option v-for="item in friendState" :key="item.Value" :label="item.Name" :value="item.Value">
								</el-option>
							</el-select>
							
							<el-button type="primary" style="margin-right: 10px;margin-bottom: 10px;" @click="pageIndex=1;getList()">查询</el-button>
							<el-button type="primary" style="margin-right: 10px;margin-bottom: 10px;" @click="importVisible=true">导入客户
							</el-button>
							<el-button type="primary" plain style="margin-right: 10px;margin-bottom: 10px;" @click="handleToRecord">导入记录
							</el-button>
							<el-button type="primary" plain style="margin-right: 10px;margin-bottom: 10px;" @click="followUpVisible=true">
								客户跟进</el-button>
							<el-button type="primary" plain style="margin-right: 10px;margin-bottom: 10px;" @click="smsvisible=true">
							短信与外呼</el-button>
						</div>
						
			
						<el-link type="info" class="help" href="https://jusnn6k8al.feishu.cn/docs/doccncRSG37Ct57ZZoZA8JEKnEb"
							target="_blank">
							<i class="el-icon-tickets" style="margin-right: 3px;"></i>批量加好友操作说明
						</el-link>
					</div>
					<div>
						<el-button type="text" @click="reset">重置查询条件</el-button>
					</div>
					<el-table :data="list" ref="multipleTable" style="width: 100%" v-loading="tableLoading" @selection-change="handleSelectionChange">
						<el-table-column
								v-if="searchStateVlaue === 0||searchStateVlaue === 1||searchStateVlaue === 2"
									type="selection"
									width="55">
								</el-table-column>
						<el-table-column prop="Phone" label="联系电话">
						</el-table-column>
						<el-table-column prop="AddTime" label="导入时间">
						</el-table-column>
						<el-table-column prop="ImportChannelValue" label="导入渠道">
							<template slot="header" slot-scope="scope">
								<div class= "flexRow importPosition">
									<span :style="{color:ImportChannelList.length?'#409eff':''}">导入渠道</span>
									<el-popover	placement="bottom" trigger="click" popper-class="headerPop">
										<div class = "flexCol">
											<el-checkbox-group v-model="ImportChannelList">
												<div class = "flexCol" style="align-items:flex-start">
													<el-checkbox v-for="(v,i) in plat" :key="i" v-if="v.Value" :label="v.Value" style="margin:3px 0px">{{v.Name}}</el-checkbox>
												</div>
											</el-checkbox-group>
											<div>
												<el-button type="text" style="color:#606266;padding-bottom:0px" @click="getList">筛选</el-button>
												<el-button type="text" style="color:#606266;padding-bottom:0px" @click="ImportChannelList=[1,2,3],getList()">重置</el-button>
											</div>
										</div>
										<img src="@/assets/img/batchAddFriend-screening.png" style="width:15px;margin:6px 0px 0px 5px;cursor: pointer;"
										 slot="reference" alt="" class = "importPosition-img">
									</el-popover>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="CustomerSource" label="客户来源">
							<template slot="header" slot-scope="scope">
								<div class= "flexRow">
									<span :style="{color:CustomerSourceList.length?'#409eff':''}">客户来源</span>
									<el-popover	placement="bottom" trigger="click">
										<div class = "flexCol" style="max-width:300px">
											<el-checkbox-group v-model="CustomerSourceList">
												<div class = "flexCol" style="max-height:200px;overflow:hidden;overflow-y:auto;align-items:flex-start">
													<el-checkbox :label="''" style="margin:3px 0px">无</el-checkbox>
													<el-checkbox v-for="(v,i) in result.CustomerSourceList" :key="i" :label="v" style="margin:3px 0px">{{v}}</el-checkbox>
												</div>
											</el-checkbox-group>
											<div>
												<el-button type="text" style="color:#606266;padding-bottom:0px" @click="getList">筛选</el-button>
												<el-button type="text" style="color:#606266;padding-bottom:0px" @click="CustomerSourceList=['',...result.CustomerSourceList],getList()">重置</el-button>
											</div>
										</div>
										<img src="@/assets/img/batchAddFriend-screening.png" style="width:15px;margin:6px 0px 0px 5px;cursor: pointer;" slot="reference" alt="">
									</el-popover>
								</div>
							</template>
							<template slot-scope="scope">
								<div class = "fonthidden" v-if="scope.row.ImportChannel==1">
									{{scope.row.CustomerSource?scope.row.CustomerSource:'--'}}
								</div>
								<div v-else>--</div>
							</template>
						</el-table-column>
						<el-table-column prop="StateValue" label="好友状态">
						</el-table-column>
						<el-table-column prop="AddFriendTime" label="添加时间">
							<template slot-scope="scope">
								<div>{{scope.row.AddFriendTime || '--'}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="DirsributeMallEmployeeName" label="分配员工">
						</el-table-column>
						<el-table-column prop="DirsributeCount" label="分配次数">
							<template slot-scope="scope">
								<div>
									<div v-if="!scope.row.DirsributeCount">{{scope.row.DirsributeCount}}</div>
									<el-popover v-else width="300" trigger="hover">
										<div class="count-info">
											<div class="count-info-title" style="font-size: 14px;font-weight: bold;margin-bottom: 10px;">分配信息</div>
											<div class="info" style="margin-bottom: 5px;" v-for="(item, index) in scope.row.DirsributeRecord" :key="index">
												{{item}}
											</div>
										</div>
										<el-link type="primary" slot="reference">{{scope.row.DirsributeCount}}</el-link>
									</el-popover>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="control" label="操作" width="200">
							<template slot-scope="scope">
								<el-button type="text" v-if="scope.row.State === 1 || scope.row.State === 2" @click="handleSeedNotice([scope.row], false)">提醒</el-button>
								<el-button type="text" v-if="scope.row.State === 1 || scope.row.State === 2 || scope.row.State === 0" @click="handleAllocation([scope.row], false)">分配</el-button>
								<el-button type="text" v-if="scope.row.State === 3" @click="handleToDetail(scope.row)">详情</el-button>
								<el-button type="text" v-if="scope.row.State === 1 || scope.row.State === 2 || scope.row.State === 0" style="color: #F56C6C;" @click="handleDeleteCustomer([scope.row], false)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<div class="left" style="margin-left: 15px;">
							<div v-if="searchStateVlaue === 0||searchStateVlaue === 1||searchStateVlaue === 2">
								<el-checkbox v-model="checked" @change="checkChange">当前页全选</el-checkbox>
								<el-button type="primary" plain size="mini" style="margin-left: 15px;" @click="handleAllocation(selectData, true)">批量分配</el-button>
								<el-button type="primary" plain size="mini" v-if="searchStateVlaue === 1||searchStateVlaue === 2" @click="handleSeedNotice(selectData, true)">批量提醒</el-button>
								<el-button type="danger" plain size="mini" @click="handleDeleteCustomer(selectData, true)">批量删除</el-button>
							</div>
						</div>
						<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
							:page-sizes="[5, 10, 15, 20, 30, 40]" @size-change="sizeChange" @current-change="currentChange"
							layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</div>
				</div>
				
				<div v-if="activeName==2">
					<div class = "flexRow" style="justify-content: space-between">
						<div class = "titFont">数据概况</div>
						<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnHbpj2Zp8QWMt1Sz3Vk3Osd" type="primary"
						:underline="false" target="_blank">查看数据统计说明  ></el-link>
					</div>
					<div class = "flexRow">
						<div class = "flexRow">
							<span style="font-weight: bold;padding-right: 4px;color: #606266;">导入渠道：</span>
							<el-select v-model="platValue" placeholder="请选择" style="margin-left: 10px;width: 200px;">
								<el-option v-for="item in plat" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
							</el-select>
						</div>
						<div class = "flexRow" style="margin-left:15px" v-if="platValue==1">
							<span style="font-weight: bold;padding-right: 4px;color: #606266;">客户来源：</span>
							<el-select v-model="sourceValue" placeholder="请选择" style="margin-left: 10px;width: 200px;">
								<el-option label="全部" :value="null"></el-option>
								<el-option v-for="(item,i) in result.CustomerSourceList" :key="i" :label="item" :value="item"></el-option>
							</el-select>
						</div>
						<el-button type="primary" style="margin-left:10px" @click="platChange">查询</el-button>
					</div>
					<div class="data-list">
						<div class="data-item">
							<div class="num">{{result.AllCount||0}}</div>
							<div class="txt">导入客户总数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.RepeatCount||0}}</div>
							<div class="txt">重复客户数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.WaitDirsributeCount||0}}</div>
							<div class="txt">待分配客户数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.WaitAddCount||0}}</div>
							<div class="txt">待添加客户数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.WaitPassCount||0}}</div>
							<div class="txt">待通过客户数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.AlreadyAddCount||0}}</div>
							<div class="txt">已添加客户数</div>
						</div>
						<div class="data-item">
							<div class="num">{{result.AllCount?(result.AddCompleteRate).toFixed(2)+'%':'--'}}</div>
							<div class="txt">添加完成率</div>
						</div>
					</div>
				</div>
			</el-card>

			<el-card style="margin-top: 15px;" v-if="activeName==2">
				<div class = "flexRow" style="justify-content: space-between">
					<div class = "titFont">各导入批次数据明细</div>
					<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnHbpj2Zp8QWMt1Sz3Vk3Osd" type="primary"
					 :underline="false" target="_blank">查看数据统计说明  ></el-link>
				</div>
				<div class = "flexRow" style="margin:20px 0px">
					<div class = "flexRow">
						<span style="font-weight: bold;padding-right: 4px;color: #606266;">导入时间：</span>
						<el-date-picker v-model="exportTime" type="month" placeholder="选择日期" format="yyyy-MM" :clearable="false"></el-date-picker>
					</div>
					<el-button type="primary" style="margin-left:10px" @click="currentPage1=1,getTableList()">查询</el-button>
				</div>
				<el-tabs v-model="activeNameTable" type="card" @tab-click="tablehandleClick" key="table">
					<el-tab-pane label="导入-添加转化数据" name="1"></el-tab-pane>
					<el-tab-pane label="导入-交易转化数据" name="2"></el-tab-pane>
				</el-tabs>
				<el-table :data="pushTableList" v-loading="pushTableLoading" key="pushTableList1" v-if="activeNameTable==1">
					<el-table-column prop="AddTime" label="导入时间" width="150"></el-table-column>
					<el-table-column prop="ImportChannelValue" label="导入渠道" width="120"></el-table-column>
					<el-table-column prop="CustomerSource" label="客户来源" width="200">
						<template slot-scope="scope">
							{{scope.row.CustomerSource?scope.row.CustomerSource:'--'}}
						</template>
					</el-table-column>
					<el-table-column prop="AllCount" label="导入客户总数"></el-table-column>
					<el-table-column prop="RepeatCount" label="重复客户数"></el-table-column>
					<el-table-column prop="WaitDirsributeCount" label="待分配客户数"></el-table-column>
					<el-table-column prop="WaitAddCount" label="待添加客户数"></el-table-column>
					<el-table-column prop="WaitPassCount" label="待通过客户数"></el-table-column>
					<el-table-column prop="AlreadyAddCount" label="已添加客户数"></el-table-column>
					<el-table-column prop="AddCompleteRate" label="添加完成率">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.AlreadyAddCount/scope.row.AllCount*100).toFixed(2)+'%':'--'}}
						</template>
					</el-table-column>
				</el-table>
				<el-table :data="pushTableList" v-loading="pushTableLoading" key="pushTableList2" v-if="activeNameTable==2">
					<el-table-column prop="AddTime" label="导入时间" width="150"></el-table-column>
					<el-table-column prop="ImportChannelValue" label="导入渠道" width="120"></el-table-column>
					<el-table-column prop="" label="客户来源" width="200">
						<template slot-scope="scope">
							<div class = "fonthidden">
								{{scope.row.CustomerSource?scope.row.CustomerSource:'--'}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AllCount" label="导入客户总数" width="120"></el-table-column>
					<el-table-column prop="UV" label="商城访问人数" width="120"></el-table-column>
					<el-table-column prop="AllCountAndUVConversionRate" label="导入-访问转化率" width="130">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.AllCountAndUVConversionRate).toFixed(2)+'%':'--'}}
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMemberCount" label="支付人数"></el-table-column>
					<el-table-column prop="PayOrderCount" label="支付笔数"></el-table-column>
					<el-table-column prop="PayOrderMoney" label="支付金额(元)" width="120"></el-table-column>
					<el-table-column prop="CustomerUnitPrice" label="客单价(元)" width="120"></el-table-column>
					<el-table-column prop="OrderUnitPrice" label="笔单价(元)" width="120"></el-table-column>
					<el-table-column prop="PayOrderCountPerCapita" label="人均支付笔数" width="120"></el-table-column>
					<el-table-column prop="" label="导入-支付转化率" width="130">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.AllCountAndPayOrderMemberCountConversionRate).toFixed(2)+'%':'--'}}
						</template>
					</el-table-column>
					<el-table-column prop="SubmitOrderMemberCount" label="下单人数"></el-table-column>
					<el-table-column prop="SubmitOrderCount" label="下单笔数"></el-table-column>
					<el-table-column prop="SubmitOrderMoney" label="下单金额(元)" width="120"></el-table-column>
					<el-table-column prop="SubmitOrderMoneyPerCapita" label="人均下单金额(元)" width="130"></el-table-column>
					<el-table-column prop="SubmitOrderCountPerCapita" label="人均下单笔数" width="120"></el-table-column>
					<el-table-column prop="" label="导入-下单转化率" width="130">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.AllCountAndSubmitOrderMemberCountConversionRate).toFixed(2)+'%':'--'}}
						</template>
					</el-table-column>
					<el-table-column prop="" label="下单-支付转化率" width="130">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate).toFixed(2)+'%':'100%'}}
						</template>
					</el-table-column>
					<el-table-column prop="" label="访问-支付转化率" width="130">
						<template slot-scope="scope">
							{{scope.row.AllCount?(scope.row.UVAndPayOrderMemberCountConversionRate).toFixed(2)+'%':'100%'}}
						</template>
					</el-table-column>
					<el-table-column prop="AvgFirstPayOrderPeriod" label="平均首单周期(天)" width="140">
						<template slot-scope="scope">
							<!-- {{scope.row.FirstPayOrderPeriodSum}} -->
							{{getAverageCycle(scope.row.FirstPayOrderPeriodSum,scope.row.AllCount)}}
							<!-- {{scope.row.AvgFirstPayOrderPeriod?scope.row.AvgFirstPayOrderPeriod:'<1天'}} -->
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderProfit" label="订单总利润(元)" width="120">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.PayOrderProfit<0" style="color:#f56c6c">
									{{scope.row.PayOrderProfit}}
								</div>
								<div v-else>
									{{scope.row.PayOrderProfit}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderAvgProfit" label="订单平均利润(元)" width="130">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.PayOrderAvgProfit<0" style="color:#f56c6c">
									{{scope.row.PayOrderAvgProfit}}
								</div>
								<div v-else>
									{{scope.row.PayOrderAvgProfit}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderProfitPerCapita" label="人均订单利润(元)" width="130">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.PayOrderProfitPerCapita<0" style="color:#f56c6c">
									{{scope.row.PayOrderProfitPerCapita}}
								</div>
								<div v-else>
									{{scope.row.PayOrderProfitPerCapita}}
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="margin-top:10px;text-align:right" v-if="total1">
					<el-pagination
						@size-change="handleSizeChange1"
						@current-change="handleCurrentChange1"
						:current-page="currentPage1"
						:page-sizes="[10, 20, 30, 40]"
						:page-size="sizepage1"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total1">
					</el-pagination>
				</div>
			</el-card>
		
			<!-- 导入客户 -->
			<addFriendImport :visible="importVisible" :sourcelist="result.CustomerSourceList" @close="importVisible=false" @reload="getList"></addFriendImport>
		
			<!-- 客户跟进 -->
			<follow-up :visible="followUpVisible" :employeeList="employeeList" @close="followUpVisible=false"></follow-up>
			
			<!-- 分配 -->
			<allocation :visible="showAllocation" :isBatch="isBatch" :selectData="selectData" @reload="getList" :employeeList="employeeList" @close="showAllocation=false"></allocation>
		
			<!-- 短信与外呼 -->
			<smsconfiguration v-if="smsvisible" :smsvisible="smsvisible" @closesms="smsvisible=false"></smsconfiguration>
		</div>
	</batchAddFriendNoAuth>
</template>

<script>
	import addFriendImport from './components/addFriendImport.vue';
	import followUp from './components/followUp.vue';
	import allocation from './components/allocation.vue';
	import smsconfiguration from "./components/smsconfiguration"
	
	import batchAddFriendNoAuth from './components/batchAddFriendNoAuth.vue';

	import {
		qyWeixinAddFriendinit,
		qyWeixinAddFriendlist,
		qyWeixinAddFriendremind,
		qyWeixinAddFrienddelete,
		qyWeixinAddFriendimportRecordstatislist
	} from '@/api/sv1.0.0.js';

	import {
		enumList
	} from '@/api/TurnTomySelf.js'

	export default {
		components: {
			addFriendImport,
			followUp,
			allocation,
			batchAddFriendNoAuth,
			smsconfiguration
		},
		created () {
			if(this.$route.params.TagDetailId){
				this.importVisible = true
			}
		},
		data() {
			return {
				activeName:'1',
				platprototype:[],
				plat: [],
				platValue: null,

				friendState: [],
				friendStateValue: null,
				searchStateVlaue: null,

				tableData: [],


				importVisible: false,
				followUpVisible: false,

				checked: false,

				dataLoading: false,
				result: {},
				employeeList: [],
				
				keywords: '',

				tableLoading: false,
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				list: [],
				
				selectData: [],
				isBatch: false, //是否是批量操作
				showAllocation: false,

				smsvisible:false,//短信提醒
				sms:{},

				ImportChannelList:[1,2,3],
				CustomerSourceList:[],

				sourceValue:null,
				activeNameTable:'1',
				exportTime:new Date(),
				pushTableList:[],
				pushTableLoading:false,
				currentPage1:1,
				sizepage1:20,
				total1:null,
			};
		},
		beforeMount() {
			this.init();
			this.getList();

			this.getEnumList('EnumQyWeixinAddFriendState').then(res => {
				this.friendState = [{
					Value: null,
					Name: '全部'
				}].concat(res)
			});
			this.getEnumList('EnumImportChannel').then(res => {
				this.plat = [{
					Value: null,
					Name: '全部导入渠道'
				}].concat(res)
				// console.log(res)
			});
		},
		methods: {
			getAverageCycle(a,b){
				if(a==0||b==0){
					return '--'
				}else{
					let x = a/60/60/24/b
					if(x<1){
						return '<1天'
					}else{
						return (x).toFixed(2)+'天'
					}
				}
			},
			initMonth(time){
				if(time){
					let year = time.getFullYear()
					let month = time.getMonth()+1>10?time.getMonth()+1:('0'+(time.getMonth()+1))
					return {
						year,month
					}
				}
			},
			handleSizeChange1(e){
				this.currentPage1 = 1
				this.sizepage1 = e
				this.getTableList()
			},
			handleCurrentChange1(e){
				this.currentPage1 = e
				this.getTableList()
			},
			async getTableList(){
				try{
					this.pushTableLoading = true
					let data = {
						ImportYear:this.initMonth(this.exportTime).year,
						ImportMonth:this.initMonth(this.exportTime).month,
						Skip:(this.currentPage1-1)*this.sizepage1,
						Take:this.sizepage1,
					}
					let res = await qyWeixinAddFriendimportRecordstatislist(data)
					if(res.IsSuccess){
						this.pushTableList = res.Result.Results
						this.total1 = res.Result.Total
					}
				}finally{
					this.pushTableLoading = false
				}
			},
			tablehandleClick(e){
				this.currentPage1=1
				this.sizepage1=20
				this.total1=null
				this.getTableList()
			},
			reset(){
				this.ImportChannelList = [1,2,3]
				this.CustomerSourceList = ['',...this.result.CustomerSourceList]
				this.keywords = ''
				this.friendStateValue = null
			},
			handleClick(e){
				this.currentPage1 = 1
				this.sizepage1 = 20
				this.total1 = null
				this.pageSize = 10
				this.pageIndex = 1
				this.total = 0
				this.init();
				this.getEnumList('EnumQyWeixinAddFriendState').then(res => {
					this.friendState = [{
						Value: null,
						Name: '全部'
					}].concat(res)
				});
				this.getEnumList('EnumImportChannel').then(res => {
					this.plat = [{
						Value: null,
						Name: '全部导入渠道'
					}].concat(res)
				});
				if(e.name==1){
					this.getList();
				}else{
					// console.log(this.activeNameTable)
					this.activeNameTable = '1'
					this.tablehandleClick({name:1})
					//
				}
			},
			getEnumList(name) {
				return new Promise(async (resolve, reject) => {
					try {
						const res = await enumList({
							EnumName: name
						})
						resolve(res.Result);
					} catch (e) {
						resolve([]);
					}
				})
			},
			async init() {
				this.dataLoading = true;
				try {
					const res = await qyWeixinAddFriendinit({
						ImportChannel: this.platValue,
						CustomerSource:this.platValue==1?this.sourceValue:null
					});
					this.result = res.Result;
					// console.log(this.result.CustomerSourceList)
					this.CustomerSourceList = ['',...(this.result.CustomerSourceList||[])]
					this.employeeList = res.Result.MallEmployeeBaseInfoList;
				} catch (e) {} finally {
					this.dataLoading = false;
				}

			},
			platChange() {
				this.init();
			},

			// 列表
			async getList() {
				this.tableLoading = true;
				try {
					const res = await qyWeixinAddFriendlist({
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						ImportChannelList:this.ImportChannelList,
						CustomerSourceList:this.CustomerSourceList,
						ImportChannel: this.platValue,
						Keywords: this.keywords,
						State: this.friendStateValue
					})

					if (res.IsSuccess) {
						this.list = res.Result.Results;
						this.total = res.Result.Total;
						this.searchStateVlaue = this.friendStateValue
					}
				} catch (e) {} finally {
					this.tableLoading = false;
				}
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			handleSelectionChange(list){
				this.checked = list.length == this.list.length;
				this.selectData = list;
			},
			checkChange(bol){
				if (bol){
					this.list.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row, true);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				} 
			},
			
			//分配
			handleAllocation(list, isBatch){
				if (!list.length){
					this.$message.error('请选择分配客户')
					return;
				}
				this.isBatch = isBatch;
				this.showAllocation = true;
				this.selectData = list;
			},

			//提醒操作
			handleSeedNotice(list, isBatch) {
				
				if (!list.length){
					this.$message.error('请先选择客户')
					return;
				}
				
				var str = '';
				if (isBatch){
					str = '是否确认给'+list.length+'个员工批量发送提醒？提醒内容将通过企业微信【企店助手】通知员工'
				}else{
					str = '是否确认给员工【'+list[0].DirsributeMallEmployeeName+'】发送提醒？提醒内容将通过企业微信【企店助手】通知员工'
				}
				
				this.$confirm(str, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.sureSeedNotice(list)
				})
			},
			async sureSeedNotice(list) {
				try{
					const res = await qyWeixinAddFriendremind({
						QyWeixinAddFriendIdList: list.map(item=>{
							return item.Id
						})
					})
					
					if (res.IsSuccess){
						this.$message.success('操作成功')
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			
			//删除
			handleDeleteCustomer(list, isBatch){
				if (!list.length){
					this.$message.error('请先选择客户')
					return;
				}
				
				var str = '';
				if (isBatch){
					str = '是否确认删除这'+list.length+'个客户？'
				}else{
					str = '是否确认删除客户【'+list[0].Phone+'】？'
				}
				
				this.$confirm(str, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.sureDelete(list)
				})
			},
			async sureDelete(list) {
				try{
					const res = await qyWeixinAddFrienddelete({
						QyWeixinAddFriendIdList: list.map(item=>{
							return item.Id
						})
					})
					
					if (res.IsSuccess){
						this.$message.success('删除成功');
						this.getList();
					}
				}catch(e){
				}
			},

			handleToRecord() {
				this.$router.push({
					name: 'batchAddFriendRecord'
				})
			},
			handleToDetail(record){
				this.$router.push({
					name: 'CustomerDetail',
					query: {
						Id: record.MemberId
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.titFont{
		font-size: 16px;
		font-weight: bold;
		color: #303133;
	}
	.flexRow{
		display: flex;
		align-items: center;
		// justify-content: space-between;
	}
	.flexCol{
		display: flex;
		flex-direction: column;
	}
	.fonthidden{
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	.container {
		font-size: 14px;
		
		.count-info{
			.count-info-title{
				font-size: 14px;
				font-weight: bold;
			}
		}

		.data-list {
			display: flex;
			justify-content: space-between;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			border-radius: 4px;
			margin-top: 15px;

			.data-item {
				height: 80px;
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.num {
					font-size: 18px;
				}

				.txt {
					margin-top: 10px;
					font-size: 13px;
					color: #666;
				}
			}
		}

		.search {
			position: relative;
			margin-bottom: 15px;

			.help {
				position: absolute;
				right: 0;
				top: 5px;
				white-space: nowrap;
				color: #666;
				cursor: pointer;
			}
		}

		.pagination {
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
	}
</style>
<style>
.headerPop{
	/* border:1px solid black */
}
</style>
