<template>
	<div class="allocation">
		<el-dialog :title="isBatch? '批量分配客户' : '分配客户'" :visible="visible" width="700px" :close-on-click-modal="false" @close="handleClose">
			<div v-if="visible">
				<div class="employee-info" v-if="!isBatch">
					【{{selectData[0].Phone}}】 当前分配员工：{{selectData[0].DirsributeMallEmployeeName||'无'}}
				</div>
				<el-form label-width="100px">
					<el-form-item label="分配员工">
						<el-select v-model="list" v-if="isBatch" multiple filterable default-first-option 
							placeholder="请选择员工" style="width: 400px;">
							<el-option v-for="item in employeeList" :key="item.Id" :label="item.EmployeeName"
								:value="item.Id">
							</el-option>
						</el-select>
						<el-select v-model="list" v-else filterable default-first-option
							placeholder="请选择员工" style="width: 400px;">
							<el-option v-for="item in employeeList"  :key="item.Id" :label="item.EmployeeName"
								:value="item.Id">
							</el-option>
						</el-select>
						<div class="tip">请选择需要分配的新员工，本次分配并不会绑定与员工的锁粉关系</div>
					</el-form-item>
				</el-form>
			</div>
			

			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="handleSave">确 定</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import config from '@/config';
	
	import {
		qyWeixinAddFrienddirsribute
	} from '@/api/sv1.0.0.js';
	
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isBatch: {
				type: Boolean,
				default: false
			},
			employeeList: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			selectData: {
				type: Array,
				default: ()=>{
					return []
				}
			},
		},
		data() {
			return {
				loading: false,
				list: []
			}
		},
		watch: {
			visible(){
				if (this.visible){
					this.list = []
				}
			}
		},
		methods: {
			handleClose() {
				this.$emit('close')
			},
			async handleSave(){
	
				if(this.isBatch && !this.list.length){
					this.$message.error('请选择分配员工');
					return;
				}
				if(!this.isBatch && !this.list){
					this.$message.error('请选择分配员工');
					return;
				}
				
				let params = {
					QyWeixinAddFriendIdList: this.selectData.map(item=>{
						return item.Id
					}),
					MallEmployeeIdList: this.list
				}
				
				this.loading = true;
				try{
					const res = await qyWeixinAddFrienddirsribute(params);
					if (res.IsSuccess){
						this.handleClose();
						this.$emit('reload')
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.loading = false;
				}
			}
		}
	}
</script>

<style lang="less">
	.allocation {

		font-size: 14px;
		
		.employee-info{
			font-size: 15px;
			margin-bottom: 35px;
			margin-left: 24px;
		}

		.tip {
			font-size: 12px;
			color: #999;
		}
	}
</style>
