<template>
  <div>
    <el-dialog :visible="smsvisible" title="短信与外呼提醒配置" @close="closesms" width="1200px" v-loading="loading" custom-class="smsdialog">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="短信提醒" name="1"></el-tab-pane>
				<el-tab-pane label="外呼提醒" name="2"></el-tab-pane>
			</el-tabs>
			<div v-show="activeName==1">
				<div class = "flexRow">
					<div style="flex:1">
						<div style="margin:0px 0px 20px 20px">
							<div class = "flexRow">
								<span style="line-height:1.5">短信提醒：</span>
								<el-switch v-model="welruform.IsOpenSMSRemind" @change="changeswich"></el-switch>
							</div>
							<div style="margin-top:10px">
								<div class ="grayfont">
									1.开启短信提醒后，员工在工作台添加客户页面，点击【复制】，将给对应的手机号自动发送一条短信提醒，以提高添加通过率。
								</div>
								<div class ="grayfont">
									2.为避免过度营销打扰客户：同一个手机号，1天仅会收到1条短信提醒；若手机号重新分配给其他的员工，若客户当天已收到该提醒，不再发送
								</div>
								<div class ="grayfont">
									3.为保证短信发送成功率，请确保商城账户内，短信剩余数量充足，当前剩余数量：
									<span style="color:#f56c6c">{{allnum}}条</span>
								</div>
							</div>
						</div>
						<div class = "flexRow" v-if="welruform.IsOpenSMSRemind">
							<div style="flex:1">
								<el-form :model="welruform" :rules="welrules" ref="welruform" label-width="100px">
									<el-form-item label="短信签名：" class = "flexRowcenter" >
										<div style="margin-left:-100px">
											<div v-if="!inputshow" class = "flexRowcenter">
												<div>{{welruform.SMSHeadSign}}</div>
												<el-button type="text" style="margin:0px 0px 0px 10px" @click="showinput">修改</el-button>
											</div>
											<div class = "flexRowcenter" v-else>
												<el-input v-model.trim="inputmsg" style="width:200px" placeholder="请输入签名"></el-input>
												<el-button type="text" style="margin:0px 5px 0px 10px" @click="focuslabel">保存</el-button>
												<el-button type="text" style="color:#303133" @click="inputshow=false">取消</el-button>
											</div>
										</div>
									</el-form-item>
									<el-form-item prop="SMSContent" label="短信内容：">
										<div class="edit-wraper">
											<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only"
												@input="checkLength"></div>
											<div class="num">{{length}}/500</div>
										</div>
									</el-form-item>
									<div class = "flexRow" style="margin:50px 0px 0px 100px">
										<div>
											<div class = "grayfont">包含签名和尾缀，已输入<span style="color:#409eff">
												{{length+welruform.SMSHeadSign.length+welruform.SMSTail.length+1}}</span>个字，按
											<span style="color:#409eff">{{smsnum}}</span>条短信发送和计费
											</div>
										</div>
									</div>
								</el-form>
							</div>
						</div>
				
					</div>
					
					<div class = "imgbox" v-if="welruform.IsOpenSMSRemind">
						<img :src="imgurl+'/image/smsconfig.png'" style="width:100%;" alt="">
						<div class = "imgposition">
							<div class = "contentbox" >
								{{welruform.SMSHeadSign}}
								<span ref="smsfonttext" style="white-space: pre-wrap;">{{welruform.SMSContent}}</span>
								{{welruform.SMSTail}}
							</div>
						</div>
					</div>
				</div>
				<div style="text-align:center">
					<el-button @click="closesms">取消</el-button>
					<el-button type="primary" @click="confirmhtml('welruform',1)">确定</el-button>
				</div>
			</div>
			<div v-show="activeName==2">
				<div style="margin:0px 0px 20px 20px">
					<div class = "flexRow">
						<span style="line-height:1.5">外呼提醒：</span>
						<el-switch v-model="welruform.IsOpenOutCallRemind"></el-switch>
					</div>
					<div v-if="welruform.IsOpenOutCallRemind">
						<div style="margin-top:10px">
							<div class ="grayfont">
								1.开启外呼提醒后，员工在工作台添加客户页面，点击【复制】，将给对应的手机号自动拨打电话，以提高添加通过率。
							</div>
							<div class ="grayfont">
								2.为避免过度营销打扰客户：同一个手机号，近7天仅会收到1通外呼提醒（未接通成功不计入在内）
							</div>
							<div class ="grayfont">
								3.电话拨打并接通成功后，将扣减商城短信剩余数量，收费标准：接通1次扣减
								<span style="color:#f56c6c">2条</span>
								短信数量；请确保商城账户内，短信剩余数量充足，当前剩余数量：
								<span style="color:#f56c6c">{{allnum}}条</span>
							</div>
						</div>
						<div style="margin-top:15px">
							<div class = "flexRow">
								<span style="line-height:1.5">外呼话术：</span>
								<div class ="grayfont">
									话术不支持自定义，请选择企店已向运营商申请通过的话术。我们正在向运营商申请更多话术，以便提供更多选择
								</div>
							</div>
							<el-select v-model="welruform.OutCallWordsId" :disabled="true" style="margin:5px 0px 0px 70px;width:600px">
								<el-option :value="1" :label="`您好，我是${welruform.MallName}的售后客服，请您留意通知，领取您的专属福利`"></el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div style="text-align:center">
					<el-button @click="closesms">取消</el-button>
					<el-button type="primary" @click="confirmhtml('welruform',2)">确定</el-button>
				</div>
			</div>
    </el-dialog>
  </div>
</template>

<script>
import {
		mallsmsmallsmsinfo
	} from '@/api/api.js';
import config from "@/config"
import { mapGetters } from 'vuex'
import {
		qyWeixinAddFriendfollowget,
		qyWeixinAddFriendfollowupdate
	} from '@/api/sv1.0.0.js';
// import linkPop from './miniprogramLink.vue';
// import miniprogramCard from './miniprogramCard.vue';
// import linkModal from './linkModal';
// import livecode from "../livecode"

export default {
	components: {
		// linkPop,
		// miniprogramCard,
		// linkModal,
		// livecode
	},
  props: {
    smsvisible:{
      type:Boolean,
      default:false,
    },
		rule:{
			type:Array,
			default:()=>{
				return []
			}
		},
  },
  data () {
		var checkWelcome = (rule, value, callback) => {
      // console.log(value,123)
      let testnull = /^ [\s]*$/
      if(!this.filterlabel(value)||testnull.test(value)){
        callback(new Error('请输入短信内容'))
      }else{
        callback()
      }
    }
    return {
			activeName:'1',
			imgurl:config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      length:0,
			welruform:{
				SMSContent:'',
				SMSHeadSign:'',
				SMSTail:'',
				IsOpenSMSRemind:false,
				IsOpenOutCallRemind:false,
				OutCallWordsId:0,
			},
			welrules:{
				SMSContent:[
          { required: true, validator: checkWelcome, trigger: 'blur' },
				]
			},

			inputshow:false,
			inputmsg:'',

			loading:false,
			allnum:0,

			originalData:{},
    }
  },
	computed:{
		smsnum(){
			let length = this.length+this.welruform.SMSHeadSign.length+this.welruform.SMSTail.length+1
			let num = 1
			if(length <= 70){
				num = 1
			}else{
				num = Math.ceil(length/67)
			}
			// console.log(num)
			return num
		},
		...mapGetters(['ShopName'])
	},
	created () {
		this.init()
		this.gethtmlmsg()
		this.getsmsmsg()
		// console.log(1)
	},
  methods: {
		handleClick(e){
			// console.log(e.name)
		},
		changeswich(val){
			// console.log(this.welruform.SMSContent)
			if(!val) return 
			this.$nextTick(()=>{
				document.querySelector('.edit-box').innerHTML = this.welruform.SMSContent
				this.length = this.filterlabel(this.welruform.SMSContent).length
			})
		},
    init(){
			this.welruform.SMSContent =''
			this.welruform.SMSHeadSign = '【'+this.ShopName+'】'
			this.welruform.SMSTail = '拒收请回复R'
		},
		showinput(){
			this.inputmsg = this.welruform.SMSHeadSign.substring(1,this.welruform.SMSHeadSign.length-1)
			this.inputshow = true
		},
		focuslabel(){
			if(this.inputmsg.length>=2 && this.inputmsg.length<=12){
				this.welruform.SMSHeadSign = '【'+this.inputmsg+'】'
				this.inputshow = false
				this.inputmsg = ''
			}else{
				this.$message.error('签名字数请输入2~12个字之间')
			}
		},
		confirmhtml(formName,n){
			if(n==1){
				if(this.welruform.IsOpenSMSRemind){
					this.welruform.SMSContent = this.filterlabel(document.querySelector('.edit-box').innerHTML)
					this.$refs[formName].validate((valid) => {
							if (valid) {
								this.update(n)
							} else {
								return false;
							}
					});
				}else{
					this.update(n)
				}
			}else if(n==2){
				this.update(n)
			}
			
		},
		async update(num){
			this.loading = true
			try{
				let data = {}
				let n = await num
				if(n==1){
					data = JSON.parse(JSON.stringify(this.welruform))
					if(!data.SMSHeadSign){
						data.SMSHeadSign = `【${this.ShopName}】`
					}
					if(!data.SMSTail){
						data.SMSTail = '拒收请回复R'
					}
					data.IsOpenOutCallRemind = this.originalData.IsOpenOutCallRemind
					data.OutCallWordsId = this.originalData.OutCallWordsId
				// console.log(data,1)
				}else if(n==2){
					let newdata = JSON.parse(JSON.stringify(this.originalData))
					newdata.IsOpenOutCallRemind = this.welruform.IsOpenOutCallRemind
					newdata.OutCallWordsId = this.welruform.OutCallWordsId
					data = newdata
				// console.log(data,2)
				}
				let res = await qyWeixinAddFriendfollowupdate(data)
				if(res.IsSuccess){
					this.$message.success('操作成功')
					this.closesms()
				}
			}finally{
				this.loading = false
			}
		},
    closesms(){
			this.welruform.SMSContent = ""
			this.welruform.file = {
					url:'',
					name:'',
					desc:'',
					Type:3
			}
			if(this.welruform.IsOpenSMSRemind){
				document.querySelector('.edit-box').innerHTML = ""
				this.$refs['welruform'].clearValidate();
			}
      this.$emit('closesms')
    },
		filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    checkLength() {
				var html = document.querySelector('.edit-box').innerHTML;
				// html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
				this.length = this.filterlabel(html).length
				this.welruform.SMSContent = document.querySelector('.edit-box').innerHTML
				this.$refs.smsfonttext.innerHTML = document.querySelector('.edit-box').innerHTML
				this.$refs.welruform.validate()
				// console.log(document.querySelector('.edit-box').innerHTML)
		},
    editBlur() {
				var html = document.querySelector('.edit-box').innerHTML;
				if (html.length > 500) {
					document.querySelector('.edit-box').innerHTML = html.substr(0, 500);
				}
				this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
				this.welruform.SMSContent = document.querySelector('.edit-box').innerHTML
				this.$refs.smsfonttext.innerHTML = document.querySelector('.edit-box').innerHTML
				// this.$emit('gethtml',document.querySelector('.edit-box').innerHTML)
		},
		async getsmsmsg(){
			this.loading = true
			try{
				let res = await mallsmsmallsmsinfo()
				// console.log(res)
				this.allnum = res.Result.SMSCount
			}finally{
				this.loading = false
			}
		},
		async gethtmlmsg(){
			try{
				this.loading = true
				let res = await qyWeixinAddFriendfollowget()
				if(res.IsSuccess){
					this.welruform = res.Result
					this.welruform.OutCallWordsId = 1
					// console.log(res.Result,'1')
					if(!res.Result.SMSHeadSign){
						this.welruform.SMSHeadSign = `【${res.Result.MallName}】`
					}
					if(!res.Result.SMSTail){
						this.welruform.SMSTail = '拒收请回复R'
					}
					if(this.welruform.IsOpenSMSRemind){
						this.$nextTick(()=>{
							document.querySelector('.edit-box').innerHTML = this.welruform.SMSContent
							this.length = this.filterlabel(this.welruform.SMSContent).length
						})
					}
					this.originalData = JSON.parse(JSON.stringify(this.welruform))
				}
			}finally{
				this.loading = false
			}
		},
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.flexRowcenter{
	display: flex;
	flex-direction: row;
	align-items:center;
}
.grayfont{
	font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}

		.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}


			}

			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;

			}
		}

		.edit-wraper {
			border: 1px solid #ddd;
			background-color: #FBFDFF;
			line-height: 18px;
			border-radius: 4px;
			
			position: relative;

			.title {
				padding: 15px 15px;
				display: flex;
				border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 200px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
				border-top: 1px solid #ddd;


			}

			.file-list {
				overflow: hidden;
				display: flex;
				justify-content: space-between;
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					// background: #cccbcb;
					// padding: 5px 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// max-width: 80%;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;

						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height: 230px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}

  .control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
<style lang = "less" scoped>
.imgbox{
	position: relative;
	left: 20px;
	padding-right: 20px;
	width:383px;
	.imgposition{
		position: absolute;
		left: 0px;
		top: 110px;
		/* border:1px solid black; */
		height: 505px;
		width:360px;
		overflow: hidden;
		overflow-y: auto;
		.contentbox{
			max-width: 250px;
			min-width: 50px;
			min-height: 40px;
			background: #E9E9EB;
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			margin:50px 0px 0px 20px;
			padding: 10px;
			font-size: 14px;
			color: #606266;
			line-height: 1.5;
		}
	}
	

}
</style>
<style>

.smsdialog .el-dialog__body{
	padding:10px 20px 30px 20px
}
</style>