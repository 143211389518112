<template>
	<div class="follow-up">
		<el-dialog title="跟进设置" :visible="visible" width="900px" :close-on-click-modal="false" @close="handleClose">
			
			<div class="content">
				<el-form label-width="100px">
					<el-form-item label="未跟进提醒">
						<el-switch v-model="form.IsOpenRemind"></el-switch>
						<div class="set" v-if="form.IsOpenRemind">
							超过<el-input-number v-model="form.OverDayRemind" style="width: 60px;margin: 0 5px;" size="mini"
								:controls="false" :precision="0" :min="0"></el-input-number>天，员工未给客户发送好友申请，次日9:00给员工发送添加提醒
						</div>
						<div class="tip" v-else>超过设置的时间，员工还未给客户发送好友申请，将自动给员工发送添加提醒</div>
					</el-form-item>
					<el-form-item label="自动回收">
						<el-switch v-model="form.IsOpenRecovery"></el-switch>
						<div class="set" v-if="form.IsOpenRecovery">
							超过<el-input-number v-model="form.OverDayRecovery" style="width: 60px;margin: 0 5px;" size="mini"
								:controls="false" :precision="0" :min="0"></el-input-number>
							天，客户未与员工添加好友，自动把客户转移到待分配，并于次日9:00提醒管理员再分配
						</div>
						<div class="tip" v-else>超过设置的时间，客户还未与员工添加成为好友，将自动把客户转移到等待分配</div>
						<div style="margin-top: 10px;" v-if="form.IsOpenRecovery">
							请选择接收提醒的员工：
							<el-select v-model="form.MallEmployeeIdList" multiple filterable default-first-option
								placeholder="请选择员工" style="width: 400px;">
								<el-option v-for="item in employeeList" :key="item.Id" :label="item.EmployeeName"
									:value="item.Id">
								</el-option>
							</el-select>
						</div>
				
					</el-form-item>
				</el-form>
			</div>
			

			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="handleSave">确 定</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import config from '@/config';
	
	import {
		qyWeixinAddFriendfollowget,
		qyWeixinAddFriendfollowupdate
	} from '@/api/sv1.0.0.js';
	
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			employeeList: {
				type: Array,
				default: ()=>{
					return []
				}
			}
		},
		data() {
			return {
				
				form: {
					IsOpenRemind: false, // 是否开启未跟进提醒,
					OverDayRemind: 3, //超过多少天提醒,
					IsOpenRecovery: false, //是否开启自动回收,
					OverDayRecovery: 3, //超过多少天回收,
					MallEmployeeIdList: [], //自动回收提醒员工（提醒这几个人再去重新操作分配）
				},
				
				loading: false
			}
		},
		watch: {
			visible(){
				if (this.visible){
					this.init()
				}
			}
		},
		methods: {
			async init(){
				try{
					const res = await qyWeixinAddFriendfollowget();
					this.form = Object.assign(this.form, res.Result);
				}catch(e){
					//TODO handle the exception
				}
			},
			handleClose() {
				this.$emit('close')
			},
			async handleSave(){
				
				if (this.form.IsOpenRemind && !this.form.OverDayRemind){
					this.$message.error('请设置未跟进提醒时间');
					return;
				}
				if (this.form.IsOpenRecovery && !this.form.OverDayRecovery){
					this.$message.error('请设置自动回收时间');
					return;
				}
				if (this.form.IsOpenRecovery && this.form.IsOpenRemind && this.form.OverDayRecovery < this.form.OverDayRemind){
					this.$message.error('未跟进提醒时间不可大于等于自动回收时间');
					return;
				}
				
				this.loading = true;
				try{
					const res = await qyWeixinAddFriendfollowupdate(this.form);
					if (res.IsSuccess){
						this.handleClose()
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.loading = false;
				}
			}
		}
	}
</script>

<style lang="less">
	.follow-up {

		font-size: 14px;

		.content {
			max-height: 500px;
			overflow: auto;
		}
		
		.set{
			margin-top: 10px;
		}

		.tip {
			font-size: 12px;
			color: #999;
		}
	}
</style>
